import {
  SquaresPlusIcon,
  UserIcon,
  FaceSmileIcon,
} from "@heroicons/react/20/solid";
import PhoneButton from "../assets/PhoneButton.png";
import moment from "moment-timezone";

const checkTimeInRomania = () => {
  // Setează zona orară pentru România
  const now = moment.tz("Europe/Bucharest");

  // Definește intervalul orar
  const startHour = moment
    .tz("Europe/Bucharest")
    .startOf("day")
    .set({ hour: 8, minute: 0, second: 0 });
  const endHour = moment
    .tz("Europe/Bucharest")
    .startOf("day")
    .set({ hour: 18, minute: 30, second: 0 });

  // Verifică dacă ora curentă este între 8:00 și 18:30 și dacă ziua este între Luni și Vineri
  const isWeekday = now.isoWeekday() >= 1 && now.isoWeekday() <= 5; // 1 = Luni, 5 = Vineri
  const isInTimeInterval = now.isBetween(startHour, endHour);

  return isWeekday && isInTimeInterval;
};

const Steps = ({ currentStep, lang }) => {
  const isWithinTimeRange = checkTimeInRomania();
  return (
    <nav aria-label="Progress" className="w-full">
      <div className="flex lg:items-center justify-between w-full">
        <div className="flex flex-col lg:flex-row lg:items-center justify-center lg:w-full">
          <div className="flex gap-1">
            <span>
              <SquaresPlusIcon className="w-6 h-6 text-blue-500" />
            </span>
            <span className={currentStep === 1 ? "font-bold" : ""}>
              {lang === "ro" ? "Date programare" : "Appointment info"}
            </span>
          </div>
          <div className="items-center mx-4 flex-grow hidden lg:block">
            <div
              className={
                "h-0.5 flex-grow " +
                (currentStep === 2 || currentStep === 3
                  ? "bg-blue-300"
                  : "bg-gray-300")
              }
            />
          </div>
          <div className="flex gap-1">
            <span>
              <UserIcon
                className={
                  "w-6 h-6 " +
                  (currentStep === 2 || currentStep === 3
                    ? "text-blue-500"
                    : "text-gray-300")
                }
              />
            </span>
            <span className={currentStep === 2 ? "font-bold" : ""}>
              {lang === "ro" ? "Date personale" : "Personal Info"}
            </span>
          </div>
          <div className="items-center mx-4 flex-grow hidden lg:block ">
            <div
              className={
                "h-0.5 flex-grow " +
                (currentStep === 3 ? "bg-blue-300" : "bg-gray-300")
              }
            />
          </div>
          <div className="flex gap-1">
            <span>
              <FaceSmileIcon
                className={
                  "w-6 h-6 " +
                  (currentStep === 3 ? " text-blue-500" : " text-gray-300")
                }
              />
            </span>
            <span className={currentStep === 3 ? "font-bold" : ""}>
              {lang === "ro" ? "Finalizare" : "Review"}
            </span>
          </div>
        </div>
        <div>
          {isWithinTimeRange === true ? (
            <a
              href="tel:0219102"
              className="flex lg:hidden gap-1 text-white px-2 py-1 bg-[#C60000] hover:bg-red-600 rounded-3xl items-center w-fit mx-auto"
            >
              <img src={PhoneButton} alt="phone" className="h-4" />
              <span className="font-bold text-sm">021.9102</span>
            </a>
          ) : null}
        </div>
      </div>
    </nav>
  );
};

export default Steps;
