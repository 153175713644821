import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSlot } from "../../features/slots/SlotsSlice";
import { useState } from "react";
import Loading from "../Loading";
import DatePicker from "react-datepicker";
import "moment/locale/ro";
import { ro } from "date-fns/locale";
import { useEffect } from "react";

const Calendar = ({ loadingSlots, lang }) => {
  const slots = useSelector((state) => state.slots);
  const doctors = useSelector((state) => state.doctors.value);
  const packages = useSelector((state) => state.packages);
  const [startDate, setStartDate] = useState(
    slots.selectedSlot?.start_time
      ? new Date(slots.selectedSlot.start_time)
      : null
  );

  console.log(slots);
  console.log(startDate);
  console.log(Object.keys(slots.slots).length > 0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (slots.selectedSlot?.start_time) {
      setStartDate(new Date(slots.selectedSlot.start_time));
    } else if (Object.keys(slots.slots).length > 0) {
      let mostRecentDate = Object.keys(slots.slots).reduce((a, b) =>
        moment(a).isBefore(moment(b)) ? a : b
      );
      setStartDate(new Date(mostRecentDate));
    } else {
      setStartDate(null);
    }
  }, [slots]);

  return (
    <>
      <div className="mb-3 font-medium">
        <p>
          {lang === "ro"
            ? "Alegeți data și ora consultației:"
            : "Choose the date and time of the appointment:"}
        </p>
      </div>
      {loadingSlots === true ? (
        <div className="flex justify-center w-full">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row gap-10">
          <div className="w-full lg:w-1/2">
            <DatePicker
              className="w-full"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              // minDate={new Date()}
              // maxDate={new Date("2024-09-01")}
              inline
              locale={lang === "ro" ? ro : null}
              dateFormatCalendar={"MMM yyyy"}
              highlightDates={Object.keys(slots.slots).map(
                (date) => new Date(date)
              )}
              filterDate={(date) =>
                // check if it is in Object.keys(slots.slots)
                Object.keys(slots.slots).includes(
                  moment(date).format("YYYY-MM-DD")
                )
              }
              minDate={
                // first day of current month
                new Date(moment().startOf("month").format("YYYY-MM-DD"))
              }
              maxDate={
                // last day of + 4 months
                new Date(
                  moment().add(90, "days").endOf("month").format("YYYY-MM-DD")
                )
              }
            />
          </div>
          <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
            {startDate === null ? (
              <>
                <h2 className="mb-4 font-medium text-xl">
                  {lang === "ro"
                    ? "Alegeți o zi pentru a vedea disponibilitățile"
                    : "Choose a day to see available appointments"}
                </h2>
                <table>
                  <tbody>
                    <tr>
                      <td className="pt-2">
                        <div className="react-datepicker__day">7</div>
                      </td>
                      <td className="pt-2 pl-2">
                        {lang === "ro"
                          ? "Niciun medic disponibil in această zi"
                          : "No doctors are available on this day"}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">
                        <div className="text-gray-300 react-datepicker__day--today react-datepicker__day">
                          7
                        </div>
                      </td>
                      <td className="py-2 pl-2">
                        {lang === "ro" ? "Ziua curentă" : "Current day"}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">
                        <div className=" react-datepicker__day react-datepicker__day--highlighted">
                          7
                        </div>
                      </td>
                      <td className="py-2 pl-2">
                        {lang === "ro"
                          ? "Zile cu programări disponibile"
                          : "Days with available appointments"}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">
                        <div className="react-datepicker__day--selected react-datepicker__day react-datepicker__day--highlighted">
                          7
                        </div>
                      </td>
                      <td className="py-2 pl-2">
                        {lang === "ro"
                          ? "Ziua selectată de dumneavoastră"
                          : "The day you selected"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <h2 className="bg-[#ccc] text-center text-xl font-semibold">
                  {moment(startDate).format("DD/MM/YYYY")}
                </h2>
                <div>
                  {slots.slots[moment(startDate).format("YYYY-MM-DD")] &&
                    Object.keys(
                      slots.slots[moment(startDate).format("YYYY-MM-DD")]
                    ).map((userId) => (
                      <div>
                        <h3 className="text-sm font-semibold mt-1.5">
                          {doctors.doctors.find(
                            (el) => el.user_id === parseInt(userId)
                          )?.user_name +
                            " - " +
                            packages.packages.find(
                              (el) => el.id === packages.selectedPackageId
                            ).name +
                            " - " +
                            parseInt(
                              doctors.doctors.find(
                                (el) => el.user_id === parseInt(userId)
                              )?.value
                            ) +
                            " lei"}
                        </h3>
                        <div className="flex flex-wrap gap-2 mt-3">
                          {slots.slots[moment(startDate).format("YYYY-MM-DD")][
                            userId
                          ]
                            ?.slice()
                            ?.sort((a, b) => {
                              return moment(a.start_time).diff(
                                moment(b.start_time)
                              );
                            })
                            ?.map((date) => {
                              return (
                                <button
                                  type="button"
                                  onClick={() => {
                                    dispatch(setSelectedSlot(date));
                                  }}
                                  className={
                                    (date.id === slots.selectedSlot?.id
                                      ? "bg-blue-500 text-white hover:bg-blue-400"
                                      : "text-inherit bg-white hover:text-blue-500") +
                                    " px-1.5 border border-gray-300  leading-7 text-sm"
                                  }
                                >
                                  {moment
                                    .utc(date.start_time)
                                    .tz("Europe/Bucharest")
                                    .format("HH:mm")}
                                </button>
                              );
                            })}
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Calendar;
