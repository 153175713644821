import CryptoJS from "crypto-js";
export default function AESDecryptor(encryptedData, keyBase64) {
  const decryptData = () => {
    const key = CryptoJS.enc.Base64.parse(keyBase64);
    const dataObj = encryptedData;

    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: CryptoJS.enc.Base64.parse(dataObj.ciphertext),
      },
      key,
      {
        iv: CryptoJS.enc.Base64.parse(dataObj.iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  return decryptData();
}
