import { createSlice } from "@reduxjs/toolkit";

export const slotsSlice = createSlice({
  name: "slots",
  initialState: {
    slots: {},
    selectedDate: null,
    selectedSlot: null,
  },
  reducers: {
    setSlots: (state, action) => {
      const groupedData = {};
      // Presupunem că avem o singură cheie de dată, dar poți itera prin mai multe dacă e cazul
      Object.keys(action.payload).forEach((date) => {
        groupedData[date] = action.payload[date].reduce((acc, currentValue) => {
          // Verificăm dacă există deja un array pentru user_id-ul acesta
          if (!acc[currentValue.user_id]) {
            acc[currentValue.user_id] = [];
          }
          // Adăugăm obiectul curent la lista corespunzătoare user_id-ului
          acc[currentValue.user_id].push(currentValue);
          return acc;
        }, {});
      });
      return {
        ...state,
        slots: groupedData,
      };
    },
    setSelectedDate: (state, action) => {
      return {
        ...state,
        selectedDate: action.payload,
      };
    },
    setSelectedSlot: (state, action) => {
      return {
        ...state,
        selectedSlot: action.payload,
      };
    },
    clearSelectedSlot: (state) => {
      return {
        ...state,
        selectedSlot: null,
      };
    },
    purgeSlots: (state) => {
      return {
        ...state,
        slots: {},
        selectedDate: null,
        selectedSlot: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSlots,
  setSelectedDate,
  setSelectedSlot,
  clearSelectedSlot,
  purgeSlots,
} = slotsSlice.actions;

export default slotsSlice.reducer;
