import CryptoJS from "crypto-js";

export default function AESEncryptor(data, keyBase64) {
  if (!data || !keyBase64) return "Lipsește data sau cheia"; // Mesaj de testare

  const key = CryptoJS.enc.Base64.parse(keyBase64);
  const iv = CryptoJS.lib.WordArray.random(16); // IV de 16 bytes pentru AES CBC

  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return {
    ciphertext: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
    iv: iv.toString(CryptoJS.enc.Base64),
  };
}
