import { useSelector } from "react-redux";
import {
  createAppointment,
  getBooking,
  rescheduleBooing,
} from "../api/General";
import Header from "../components/Header";
import Steps from "../components/Steps";
import moment from "moment-timezone";
import "moment/locale/ro";
import SidebarDesktop from "../components/SidebarDesktop";
import NextPrevButtons from "../components/NextPrevButtons";
import AppointmentReview from "../components/Review/AppointmentReview";
import ReviewTable from "../components/Review/ReviewTable";
import { toast } from "react-toastify";
import ErrorModal from "../components/ErrorModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setTimestamp } from "../features/expireStates/ExpireStatesSlice";
import { purgeDoctors } from "../features/doctors/DoctorsSlice";
import { purgePackages } from "../features/packages/PackagesSlice";
import { purgeSlots } from "../features/slots/SlotsSlice";
import { purgePersonalInformation } from "../features/personalInformation/PersonalInformationSlice";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { setReferral } from "../features/referral/ReferralSlice";

moment.locale();

const Review = ({ lang, reschedule }) => {
  const [open, setOpen] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const slots = useSelector((state) => state.slots);
  const packages = useSelector((state) => state.packages);
  const personalInformation = useSelector((state) => state.personalInformation);
  const expireStates = useSelector((state) => state.expireStates);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(id !== undefined);
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    if (id !== undefined) {
      getBooking(id).then((res) => {
        if (res.status !== 200) {
          setErrorText(res.data);
          setOpen(true);
        } else {
          setBooking(res.data);
          setLoading(false);
        }
      });
    }
    if (expireStates.timestamp === null) {
      dispatch(setTimestamp(new Date().getTime()));
    } else {
      const now = new Date().getTime();
      const diff = now - expireStates.timestamp;
      if (diff > 60 * 60 * 1000) {
        dispatch(setTimestamp(new Date().getTime()));
        dispatch(purgeDoctors());
        dispatch(purgePackages());
        dispatch(purgeSlots());
        dispatch(purgePersonalInformation());
        navigate("/");
      }
    }
  }, []);
  const onSave = (e) => {
    e.preventDefault();
    if (reschedule === false) {
      const data = {
        pacient_data: {
          code: personalInformation.CNP,
          last_name: personalInformation.firstName,
          first_name: personalInformation.lastName,
          country_id: 1,
          country: null,
          area_id: null,
          area: personalInformation.region,
          city: personalInformation.city,
          address: personalInformation.address,
        },
        contact_data: {
          phone: personalInformation.phone,
          email: personalInformation.email,
        },
        user_id: slots.selectedSlot.user_id,
        slot_id: slots.selectedSlot.id,
        package_data: {
          id: packages.selectedPackageId,
          price: parseInt(slots.selectedSlot.value),
        },
        mans_data: [],
        total_price: parseInt(slots.selectedSlot.value),
        obs: personalInformation.message,
      };

      const result = createAppointment(data).then((res) => {
        if (res.status !== 200) {
          setErrorText(res.data);
          setOpen(true);
        } else {
          dispatch(
            setReferral({
              bookingId: res.data.booking_id,
              isNewPatient: res.data.is_new,
              patientId: res.data.pacient_id,
            })
          );
          window.location = lang === "ro" ? "/finish" : "/en/finish";
        }
      });

      toast.promise(
        result,
        {
          pending:
            lang === "ro" ? "Se face programarea..." : "Making appointment...",
        },
        { style: { width: "300px" } }
      );
    } else {
      const data = {
        uuid: id,
        user_id: slots.selectedSlot.user_id,
        slot_id: slots.selectedSlot.id,
        package_data: {
          id: packages.selectedPackageId,
          price: parseInt(slots.selectedSlot.value),
        },
        mans_data: [],
        total_price: parseInt(slots.selectedSlot.value),
        obs: personalInformation.message,
      };

      const result = rescheduleBooing(data).then((res) => {
        if (res.status !== 200) {
          setErrorText(res.data);
          setOpen(true);
        } else {
          window.location = lang === "ro" ? "/finish" : "/en/finish";
        }
      });

      toast.promise(
        result,
        {
          pending:
            lang === "ro"
              ? "Se face reprogramarea..."
              : "Making appointment...",
        },
        { style: { width: "300px" } }
      );
    }
  };

  return (
    <>
      <Header lang={lang} />
      <ErrorModal open={open} setOpen={setOpen} title={errorText} />
      <div>
        <SidebarDesktop lang={lang} />

        <main className="lg:pl-72 mt-16">
          <div className="px-4 sm:px-6 lg:px-8">
            <section className="m-7 p-7 max-w-6xl mx-auto">
              <Steps currentStep={3} lang={lang} />
            </section>
            <section className="m-7 p-7 bg-white max-w-6xl mx-auto">
              <div className="text-sm">
                <h3 className="my-2.5 leading-7 text-lg font-semibold">
                  {lang === "ro" ? "Rezumat programare" : "Appointment review"}
                </h3>
              </div>
              {loading ? (
                <Loading />
              ) : (
                <div className="mt-6">
                  <AppointmentReview
                    lang={lang}
                    reschedule={reschedule}
                    booking={booking}
                  />
                  <div className="w-full mt-4">
                    <ReviewTable lang={lang} />
                  </div>
                </div>
              )}
            </section>
            <section className="m-7 p-7 bg-white max-w-6xl mx-auto">
              <NextPrevButtons currentStep={3} onSave={onSave} lang={lang} />
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Review;
